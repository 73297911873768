/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onClientEntry = () => {
  if (window.navigator && window.navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
      for (let registration of registrations) {
        registration.unregister().then(unregistered => {
          console.log(
            unregistered == true ? 'unregistered' : 'failed to unregister'
          )
        })
      }
    })
  }
}
